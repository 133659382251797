jQuery.extend({
	Card: function (holder) {
		// VERSION : 170215

		// PUBLIC
		var _this = this;

		var _private = {
			// PRIVATE OBJECTS
			holder: 0,

			// PRIVATE FUNCTIONS
			setup: function () {
				_private.holder = $(holder);
				_private.body = $('body');

				_private.content = _private.holder.find('.o-card__content');
				_private.contentHeight = _private.content.outerHeight();
				_private.contentInner = _private.holder.find('.o-card__content__inner');
				_private.contentInnerHeight = _private.contentInner.outerHeight();
				_private.btn = _private.holder.find('.o-btn--rounded');
			},

			eventListener: function () {

				_private.holder.on("toggleTab", function (event) {
					_private.update();
				});

				_private.btn.bind('click', function () {

					if (_private.holder.hasClass('is--active')) {
						_private.holder.removeClass('is--active');
						_private.content.css("height", _private.contentHeight + "px");

					} else {
						_private.holder.addClass('is--active');
						_private.content.css("height", _private.contentInnerHeight + "px");
					}

				});
			},

			checkContentHeight: function () {

				if (_private.contentHeight < _private.contentInnerHeight) {
					_private.holder.addClass("btn--visible");
				}
				else {
					_private.holder.removeClass("btn--visible");
				}

			},

			update: function () {
				_private.contentInnerHeight = _private.contentInner.outerHeight();

				if (_private.holder.hasClass('is--active')) {
					_private.content.css("height", _private.contentInnerHeight + "px");
				}

				_private.checkContentHeight();
			},
		};

		// WINDOW RESIZE
		function doResize() {
			_private.update();
		}

		var resizeTimer;
		$(window).resize(function () {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(doResize, 200);
		});

		function initialize() {
			_private.setup();
			_private.checkContentHeight();
			_private.eventListener();
		}
		$(document).ready(function () {
			initialize();
		});
	}
});

//$(function () {
//var Card = new $.Card($('.o-card__projects'));

$(".o-card__projects").each(function (index) {

	var Card = new $.Card($(this));
});
//});



// jQuery.extend({
// 	Card: function (holder) {
// 		// VERSION : 170215

// 		// PUBLIC
// 		var _this = this;

// 		var _private = {
// 			// PRIVATE OBJECTS
// 			holder: 0,

// 			// PRIVATE FUNCTIONS
// 			setup: function () {

// 				_private.holder = $(holder);
// 				_private.body = $('body');

// 				_private.content = _private.holder.find('.o-card__content p');
// 				_private.btn = _private.holder.find('.o-btn--rounded');

// 				_private.textLengths = [];

// 			},
// 			eventListener: function () {
// 				_private.btn.bind('click', function () {

// 					var parentElement = $(this).closest('.o-card__projects');
// 					//var fade = parentElement.find('.o-card__projects__fade');

// 					if (parentElement.hasClass('is--active')) {
// 						//open functie
// 						parentElement.removeClass('is--active');
// 						//$(this).css('transform', 'rotate(0deg)');
// 						//fade.removeClass('test');
// 					} else {
// 						parentElement.addClass('is--active');
// 						//$(this).css('transform', 'rotate(180deg)');
// 						//fade.addClass('test');
// 					}

// 				});
// 			},


// 			checkContentLength: function () {

// 				for (var i = 0; i < _private.content.length; i++) {
// 					var text = _private.content[i].innerHTML;
// 					var textLength = text.length;

// 					_private.textLengths.push(textLength);

// 				}

// 				_private.checkIfBtn();

// 			},

// 			checkIfBtn: function () {
// 				for (var i = 0; i < _private.textLengths.length; i++) {
// 					if (_private.textLengths[i] > 200) {
// 						_private.btn[i].className += ' visible';
// 					} else {
// 						// _private.btn[i].remove();
// 					}
// 				}
// 			},

// 		};

// 		// WINDOW RESIZE
// 		function doResize() { }

// 		var resizeTimer;
// 		$(window).resize(function () {
// 			clearTimeout(resizeTimer);
// 			resizeTimer = setTimeout(doResize, 200);
// 		});

// 		function initialize() {
// 			_private.setup();
// 			_private.checkContentLength();
// 			_private.eventListener();
// 		}
// 		$(document).ready(function () {
// 			initialize();
// 		});
// 	}
// });

// $(function () {
// 	var Card = new $.Card($('.o-card__projects'));
// });
