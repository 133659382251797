jQuery.extend({
	Tabber: function (holder) {
		// VERSION : 170215

		// PUBLIC
		var _this = this;

		var _private = {
			// PRIVATE OBJECTS
			holder: 0,

			// PRIVATE FUNCTIONS
			setup: function () {

				_private.holder = $(holder);
				_private.body = $('body');

				_private.tabber = _private.holder.find('.o-tabber');
				_private.projectsBtn = _private.holder.find('.m-projects__btn');
				_private.projectsTab = _private.holder.find('.m-projects__tab');
				_private.researchBtn = _private.holder.find('.m-research__btn');
				_private.researchTab = _private.holder.find('.m-research__tab');

				_private.researchTab.addClass('test');
			},
			eventListener: function () {
				_private.projectsBtn.bind('click', function () {
					_private.toggleTab();
				});

				_private.researchBtn.bind('click', function () {
					_private.toggleTab();
				});
			},

			toggleTab: function () {


				if (_private.projectsTab.hasClass('o-tabber--active')) {
					_private.projectsTab.removeClass('o-tabber--active');
					_private.projectsBtn.removeClass('o-tabber__tab--active');
					_private.researchTab.addClass('o-tabber--active');
					_private.researchBtn.addClass('o-tabber__tab--active');
				} else {
					_private.researchTab.removeClass('o-tabber--active');
					_private.researchBtn.removeClass('o-tabber__tab--active');
					_private.projectsBtn.addClass('o-tabber__tab--active');
					_private.projectsTab.addClass('o-tabber--active');
				}

				// DISPATCH TOGGLE TAB EVENT
				$(".o-card__projects").trigger("toggleTab");
			}

		};

		// WINDOW RESIZE
		function doResize() { }

		var resizeTimer;
		$(window).resize(function () {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(doResize, 200);
		});

		function initialize() {
			_private.setup();
			_private.eventListener();
		}
		$(document).ready(function () {
			initialize();
		});
	}
});

$(function () {
	var Tabber = new $.Tabber($('.o-tabber'));
});
