jQuery.extend({
	Cookie: function (holder) {
		// VERSION : 170215

		// PUBLIC
		var _this = this;

		var _private = {
			// PRIVATE OBJECTS
			holder: 0,
			body: 0,
			closeBtn: 0,
			cookieValue: 0,

			// PRIVATE FUNCTIONS
			setup: function () {

				_private.holder = $(holder);
				_private.body = $('body');
				_private.closeBtn = _private.holder.find('.o-btn--rounded');

				_private.cookieValue = _private.getCookie('stichting-vooruit');

				if (_private.cookieValue != 'initial-cookie') {
					_private.showCookie();
				}

			},
			eventListener: function () {

				_private.closeBtn.bind('click', function () {
					_private.closeModal();
				});

			},
			showCookie: function () {

				_private.holder.removeClass("is--hidden");

			},
			closeModal: function () {

				_private.holder.addClass("is--hidden");
				_private.setCookie('stichting-vooruit', 'initial-cookie');

			},
			setCookie: function (key, value) {

				var expires = new Date();
				expires.setTime(expires.getTime() + 31536000000); //1 year
				document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';

			},
			getCookie: function (key) {

				var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
				return keyValue ? keyValue[2] : null;

			}

		};

		// WINDOW RESIZE
		function doResize() { }

		var resizeTimer;
		$(window).resize(function () {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(doResize, 200);
		});

		function initialize() {
			_private.setup();
			_private.eventListener();
		}
		$(document).ready(function () {
			initialize();
		});
	}
});

$(function () {
	var Cookie = new $.Cookie($('.m-cookie'));
});


