jQuery.extend({
	Signup: function(holder) {
		// VERSION : 170215

		// PUBLIC
		var _this = this;

		var _private = {
			// PRIVATE OBJECTS
			holder: 0,
			resultData: null,

			// PRIVATE FUNCTIONS
			setup: function() {
				// Base
				_private.holder = $(holder);
				_private.body = $('body');

				// Animation
				_private.slideAnimation = 500;

				// Form
				_private.form = _private.holder.find('#m-signup__form');

				// Slides
				_private.container = _private.holder.find(
					'.m-signup__container'
				);

				_private.slideWidth = _private.holder.width();
				_private.slides = _private.holder.find('.m-signup__slide');
				_private.slides.width(_private.slideWidth);
				_private.slideCount = _private.slides.length;
				_private.activeSlide = 0;

				_private.submitBtn = _private.holder.find('.m-singup__submit');

				// Calc container width
				_private.containerWidth =
					_private.slideWidth * _private.slideCount;

				// Dots
				_private.dots = _private.holder.find('.m-signup__dots__dot');
				_private.dotCount = _private.dots.length;

				// Data
				_private.data;

				// URL
				_private.url =
					window.location.origin + '/wp-admin/admin-post.php';

				_private.stored = _private.holder.find('.stored');
				_private.next = _private.holder.find('.m-signup__next');
				_private.prev = _private.holder.find('.m-signup__prev');
			},
			eventListener: function() {
				_private.dots.bind('click', function(e) {
					var target = $(this).index();
					var prevDot = $('.m-signup__dots').find(
						'[data-id=\'' + (_private.activeSlide + 1) + '\']'
					);
					prevDot.removeClass('active--dot');
					$(this).addClass('active--dot');
					_private.goToSlide(_private.activeSlide, target);
				});

				_private.form.bind('change', function() {
					_private.gatherData();
				});

				_private.dots.bind('click', function() {
					_private.sendToServer();
				});

				_private.submitBtn.bind('click', function(e) {
					e.preventDefault();

					_private.sendToServer();
					window.location.href = window.location.origin + '/bedankt/';
				});

				_private.next.bind('click', function(e) {
					var target =
						$(this)
							.closest('.m-signup__slide')
							.index() + 1;

					var prevDot = $('.m-signup__dots').find(
						'[data-id=\'' + (_private.activeSlide + 1) + '\']'
					);
					var nextDot = $('.m-signup__dots').find(
						'[data-id=\'' + (target + 1) + '\']'
					);
					$('.m-signup__dots__dot').removeClass('active--dot');
					nextDot.addClass('active--dot');
					_private.goToSlide(_private.activeSlide, target);
					_private.gatherData();
					_private.sendToServer();
				});

				_private.prev.bind('click', function() {
					var target =
						$(this)
							.closest('.m-signup__slide')
							.index() - 1;

					var prevDot = $('.m-signup__dots').find(
						'[data-id=\'' + (_private.activeSlide - 1) + '\']'
					);
					var nextDot = $('.m-signup__dots').find(
						'[data-id=\'' + (target + 1) + '\']'
					);
					$('.m-signup__dots__dot').removeClass('active--dot');
					nextDot.addClass('active--dot');
					_private.goToSlide(_private.activeSlide, target);
				});
			},
			storeFormData: function() {
				$('.stored').keyup(function() {
					localStorage['date'] = new Date().getTime();
					localStorage[$(this).attr('name')] = $(this).val();
				});
			},

			goToSlide: function(currentSlide, target) {
				var slideBy;

				if (currentSlide !== target) {
					if (currentSlide > target) {
						slideBy = currentSlide - target;
						_private.container.animate(
							{
								left: '+=' + _private.slideWidth * slideBy
							},
							_private.slideAnimation
						);
					} else {
						slideBy = target - currentSlide;
						_private.container.animate(
							{
								left: '-=' + _private.slideWidth * slideBy
							},
							_private.slideAnimation
						);
					}
				}

				_private.activeSlide = target;
			},

			checkLocalStorage: function() {
				var stored = localStorage['date'];
				var fortnightAway = new Date(Date.now() - 12096e5);
				if (stored < fortnightAway) {
					_private.clearLocalStorage();
				} else {
					_private.loadFromLocalStorage();
				}
			},

			gatherData: function() {
				// _private.data = $('form').serialize();

				_private.data = $('form').serializeArray();
				console.log(_private.resultData);

				if (_private.resultData != null) {
					var postID = { name: 'postID', value: _private.resultData };
					_private.data.push(postID);
				}
			},

			sendToServer: function() {
				console.log(_private.url);
				$.ajax({
					type: 'POST',
					url: _private.url,
					data: _private.data,
					success: function(result) {
						console.log(result);
						_private.resultData = result;
					},
					error: function(error) {
						console.log(error);
					}
				});
				// Test link: http://ptsv2.com/t/zd2au-1523890467
				// Post request
				// $.ajax({
				//     type: 'POST',
				//     url: _private.url + '/post',
				//     data: _private.data,
				//     success: function(result) {
				//         console.log(result);
				//     },
				//     error: function(error) {
				//         console.log(error);
				//     }
				// });
				// Get request
				// $.ajax({
				//     type: 'GET',
				//     url: _private.url + '/d/1',
				//     success: function(success) {
				//         console.log(success);
				//     },
				//     error: function(error) {
				//         console.log(error);
				//     }
				// });
			},

			setContainerWidth: function() {
				_private.container.css({
					width: _private.containerWidth
				});
			},

			reCalcWidth: function() {
				_private.slideWidth = _private.holder.width();
				_private.slides.width(_private.slideWidth);
				_private.containerWidth =
					_private.slideWidth * _private.slideCount;
				_private.setContainerWidth();
				_private.container.css({
					left: -_private.slideWidth * _private.activeSlide
				});
			},

			clearLocalStorage: function() {
				for (var i = 0; i < localStorage.length; i++) {
					var key = localStorage.key(i);
					localStorage[key] = '';
				}
			},

			loadFromLocalStorage: function() {
				for (var i = 0; i < localStorage.length; i++) {
					var key = localStorage.key(i);
					$('#' + key).val(localStorage[key]);
				}
			}
		};

		// WINDOW RESIZE
		function doResize() {
			_private.reCalcWidth();
		}

		var resizeTimer;
		$(window).resize(function() {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(doResize, 200);
		});

		function initialize() {
			_private.setup();
			_private.setContainerWidth();
			_private.eventListener();
			_private.checkLocalStorage();
			_private.storeFormData();
		}
		$(document).ready(function() {
			initialize();
		});
	}
});

$(function() {
	var Signup = new $.Signup($('.m-signup'));
});
