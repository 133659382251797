jQuery.extend({
	Navigation: function(holder) {
		// VERSION : 170215

		// PUBLIC
		var _this = this;

		var _private = {
			// PRIVATE OBJECTS
			holder: 0,

			// PRIVATE FUNCTIONS
			setup: function() {
				_private.holder = $(holder);
				_private.body = $('body');

				_private.navButton = _private.holder.find('.o-hamburger');
				_private.mobileNav = _private.holder.find(
					'.m-navigation__list'
				);

				_private.navIsCollapsed = true;
			},
			eventListener: function() {
				_private.navButton.bind('click', function(e) {
					e.preventDefault();
					_private.toggleNav();
				});
			},

			toggleNav: function() {
				if (!_private.navIsCollapsed) {
					_private.mobileNav.slideUp('slow');
					_private.mobileNav.removeClass('is--active');
					_private.navButton.removeClass('is--active');
					_private.navIsCollapsed = true;
				} else {
					_private.mobileNav.slideDown('slow');
					_private.mobileNav.addClass('is--active');
					_private.navButton.addClass('is--active');
					_private.navIsCollapsed = false;
				}
			},

			resetMenu: function() {
				if (Modernizr.mq('(min-width: 991px)')) {
					_private.mobileNav.removeClass('is--active');
					_private.navButton.removeClass('is--active');
					_private.navIsCollapsed = true;
				}
			}
		};

		// WINDOW RESIZE
		function doResize() {
			_private.resetMenu();
		}

		var resizeTimer;
		$(window).resize(function() {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(doResize, 200);
		});

		function initialize() {
			_private.setup();
			_private.eventListener();
		}
		$(document).ready(function() {
			initialize();
		});
	}
});

$(function() {
	var Navigation = new $.Navigation($('.m-navigation'));
});
